import { DataSources } from "@mixins/data-sources"
import normalizeUrl from "normalize-url"
import moment from "moment"

var numeral = require("numeral")

const FieldTypes = {
  TEXT: 0,
  RANGE: 1,
  DATE: 2,
  DROPDOWN: 3,
  USER: 4,
  FILE: 5,
  CHECKBOX: 6,
  RADIO: 7,
  NUMBER: 8,
  TAG: 9,
  GROUP: 10,
}

const FieldTypesById = {
  gtd_text: 0,
  gtd_range: 1,
  gtd_date: 2,
  gtd_dropdown: 3,
  gtd_user: 4,
  gtd_file: 5,
  gtd_checkbox: 6,
  gtd_radio: 7,
  gtd_number: 8,
  gtd_tag: 9,
  gtd_multi_field: 10,
}

const FieldTypesHumanReadable = {
  0: "Text",
  1: "Range",
  2: "Date",
  3: "Dropdown",
  4: "User",
  5: "File",
  6: "Checkbox",
  7: "Radio",
  8: "Number",
  9: "Tag",
  10: "Group",
}

const FieldCardCategories = {
  ALL_CARDS: 0,
  GROUP_FIELDS_CARDS: 1,
  CONNECTED_GROUPS_CARDS: 2,
  INTEGRATIONS_CARDS: 3,
  DATA_PROVIDERS_CARDS: 4,
}

const SelectionTypes = [
  FieldTypes.DROPDOWN,
  FieldTypes.CHECKBOX,
  FieldTypes.RADIO,
]

const CategoryTypes = [FieldTypes.CHECKBOX, FieldTypes.TAG]

const StatusTypes = [FieldTypes.DROPDOWN, FieldTypes.RADIO]

const MultiSelectRenders = [FieldTypes.TAG, FieldTypes.USER, FieldTypes.GROUP]

const FieldAttributeTypes = CategoryTypes.concat(StatusTypes)

const FieldTypeOptions = [
  { text: "Text", value: 0, hint: "A text input field." },
  {
    text: "Range",
    value: 1,
    hint: "Use a slider to set one or more values along a number range.",
  },
  { text: "Date", value: 2, hint: "Select a date on a calendar." },
  {
    text: "Dropdown",
    value: 3,
    hint: "Select a single value in a dropdown.",
  },
  {
    text: "User",
    value: 4,
    hint: "Select one or more group members from a dropdown.",
  },
  { text: "File", value: 5, hint: "Upload one or more files." },
  {
    text: "Checkbox",
    value: 6,
    hint: "Select a checkbox next to one or more values. Drag a value within another to nest it below.",
  },
  {
    text: "Radio",
    value: 7,
    hint: "Select a single value from one or more options.",
  },
  { text: "Number", value: 8, hint: "A number input field." },
  {
    text: "Tag",
    value: 9,
    // ===
    // RICO - disabled for tag field release, as of 4/14.
    // ===
    // hint:
    //   "Allow any user to create one or more tags. Existing tags appear as you type. Not case sensitive.",
  },
]

const FieldExcludeAttr = ["kt_logo_url", "kt_cb_permalink", "kt_clearbit_id"]
const FieldSourcesEditable = [DataSources.GROUP]
const FieldSourcesViewable = [
  DataSources.KITE,
  DataSources.CRUNCHBASE,
  DataSources.CLEARBIT,
  DataSources.OWLER,
  // DataSources.SIMILAR_WEB,
]

// Javasctipt will automatically round the last 3 digits over 9 quintillion
const NumericFieldLimits = {
  LOWER: -1000000000000000,
  UPPER: 1000000000000000,
}

const NumericFieldLimitsHumanReadable = {
  LOWER: NumericFieldLimits.LOWER.toString().replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  ),
  UPPER: NumericFieldLimits.UPPER.toString().replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  ),
}

const flattenCheckboxChoices = function (choices) {
  return _.reduce(
    choices,
    (arr, choice) => {
      arr.push(choice)
      if (choice.choices.length) {
        return arr.concat(flattenCheckboxChoices(choice.choices))
      }
      return arr
    },
    []
  )
}

const checkboxHidMap = function (choices) {
  return _.reduce(
    choices,
    (obj, choice) => {
      obj[choice.hid] = choice.choice
      if (choice.choices.length) {
        return Object.assign({}, obj, checkboxHidMap(choice.choices))
      }
      return obj
    },
    {}
  )
}

// ===
// Field Value Formatting
// - Field Graphs, Charts and BigNumber
// ===

// Ensure new activities are added the the appropriate `type` below for proper value renders. #NewActivity
const isDateType = function (fieldAttr) {
  // Year - clb_founded, owl_founded_date
  return _.includes(
    [
      "kt_created_at",
      "kt_updated_at",
      "cb_founded",
      "cb_ipo",
      "cb_acquired_on", // Not a real gfc field attribute
      "cb_closed_on", // Not a real gfc field attribute
    ],
    fieldAttr
  )
}

const isUrlType = function (fieldAttr) {
  return _.includes(["kt_url", "pb_website"], fieldAttr)
}

const isNumberType = function (fieldAttr) {
  return _.includes(
    [
      "clb_team",
      "owl_employee_count",
      "sw_total_visits_last_month",
      "sw_global_rank",
      "sw_global_rank_change",
      "sw_most_popular_country_rank",
      "sw_most_popular_country_rank_change",
      "pb_employees",
      "pb_total_money_raised",
    ],
    fieldAttr
  )
}

const isCurrencyType = function (fieldAttr) {
  return _.includes(
    [
      "clb_raised",
      "clb_market_cap",
      "cb_funding",
      "owl_revenue",
      "pb_total_money_raised",
    ],
    fieldAttr
  )
}

const formattedFieldData = function (fieldAttr, val) {
  if (val === "Is Empty") return val
  // Number
  if (isNumberType(fieldAttr) || isCurrencyType(fieldAttr)) {
    return numeral(val).format(isCurrencyType(fieldAttr) ? "($0.0a)" : "(0,0)")
  }
  // Date
  else if (isDateType(fieldAttr)) {
    return moment(new Date(val)).format("LL")
  }
  // URL
  else if (isUrlType(fieldAttr)) {
    return normalizeUrl(val, {
      stripProtocol: true,
      stripWWW: true,
    })
  }
  // Default
  else {
    return val
  }
}

export {
  FieldTypes,
  FieldTypesById,
  FieldTypesHumanReadable,
  SelectionTypes,
  CategoryTypes,
  FieldCardCategories,
  StatusTypes,
  MultiSelectRenders,
  FieldAttributeTypes,
  FieldTypeOptions,
  FieldExcludeAttr,
  FieldSourcesEditable,
  FieldSourcesViewable,
  NumericFieldLimits,
  NumericFieldLimitsHumanReadable,
  flattenCheckboxChoices,
  checkboxHidMap,
  // Value Formatting
  formattedFieldData,
}
