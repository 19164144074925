import { FieldTypes } from "@mixins/fields/types"

export default {
  buildBaseDatumDataByUc(uc) {
    if (
      uc === FieldTypes.TEXT ||
      uc === FieldTypes.DATE ||
      uc === FieldTypes.NUMBER
    ) {
      return { value: null }
    } else if (
      uc === FieldTypes.DROPDOWN ||
      uc === FieldTypes.CHECKBOX ||
      uc === FieldTypes.RADIO ||
      uc === FieldTypes.TAG
    ) {
      return { selected: [] }
    } else if (uc === FieldTypes.USER || uc === FieldTypes.FILE) {
      return { id_array: [] }
    } else if (uc === FieldTypes.RANGE) {
      return { min: null, max: null }
    }
  },
  buildBaseDatumDataById(id) {
    if (id === "gtd_text" || id === "gtd_date" || id === "gtd_number") {
      return { value: null }
    } else if (
      id === "gtd_dropdown" ||
      id === "gtd_checkbox" ||
      id === "gtd_radio" ||
      id === "gtd_tag"
    ) {
      return { selected: [] }
    } else if (id === "gtd_user" || id === "gtd_file") {
      return { id_array: [] }
    } else if (id === "gtd_range") {
      return { min: null, max: null }
    }
  },
}
