const labelOverride = function (meta) {
  let attr = _.get(meta, "attribute", null) || _.get(meta, "attr", null)

  if (["kt_name", "kt_url", "kt_sources", "kt_added_by_user"].includes(attr)) {
    if (attr === "kt_name") {
      return "Company Name"
    } else if (attr === "kt_url") {
      return "Company Website"
    } else if (attr === "kt_sources") {
      return "Available Data"
    } else if (attr === "kt_added_by_user") {
      return "Initially Added By"
    }
  } else {
    return _.get(meta, "label", null) || _.get(meta, "text", null) || "Field"
  }
}

export { labelOverride }
