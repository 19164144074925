// Search default options
// "/api/v1/search?q=${searchObject}"

// Options to scope search results
// indices: ["users", "groups", "networks", "companies"],
// types: ["text", "term", "integer"],
// verbs: ["must", "must_not", "should", "should_not"],
// per_page: 50
// page: 1

import toInteger from "lodash/toInteger"

export default {
  groupIncludes: [
    "name",
    "brand_color",
    "branding_color",
    "enabled",
    "permalink",
    "user_count",
    "group_companies_count",
    "group_reports_count",
    "branding_logo",
    "containers_count",
    "session_timeout",
    "model_type",
    "srm_enabled",
    "ecosystem_banner_url",
    "context_view_meta",
  ],
  notificationIncludes: [
    "use_case",
    "viewed",
    "user_id",
    "group_id",
    "child_user_id",
  ],
  userIncludes: [
    "name",
    "username",
    "title",
    "email",
    "phone_number",
    "active",
    "last_active_date",
    "privacy_consent_date",
    "avatar_url",
  ],
  groupCompaniesIncludes: [
    "id",
    "kt_name",
    "kt_url",
    "kt_logo_url",
    "logo_url",
    "cb_permalink",
    "kt_model",
    "kt_sources",
    "context_view_meta",
    "kt_added_by_user",
  ],
  companyIncludes: [
    "name",
    "url",
    "domain",
    "cb_permalink",
    "clearbit_id",
    "logo_url",
  ],
  commentIncludes: [
    "commentable_type",
    "model_type",
    "commentable_id",
    "content_markdown",
    "content",
    "group_id",
    "user_id",
    "user_name",
    "user_title",
    "user_avatar_url",
  ],
  reportIncludes: ["name"],
  widgetIncludes: [
    "group_id",
    "report_id",
    "field_id",
    "field_label",
    "context_view_id",
    "use_case",
    "dimension",
    "context_data",
    "attachment_data",
    "model_type",
  ],
  activityIncludes: [
    "created_at",
    "activity_type",
    "triggered_by_type",
    "triggered_by_id",
    "source_one_type",
    "source_one_id",
    "source_two_type",
    "source_two_id",
    "util_int_one",
    "group_id",
    "provider_name",
  ],
  attachmentIncludes: ["file_url", "file_name", "file_type"],
  owlerCompanyNewsIncludes: [
    "id",
    "company_id",
    "enclosure_image",
    "source_url",
    "publisher_name",
    "publisher_logo",
    "title",
    "description",
    "feed_date",
  ],
  navbarAutocompleteIncludes: [
    "id",
    "kt_name",
    "kt_logo_url",
    "name",
    "avatar_url",
    "title",
  ],
  fieldCardTemplateIncludes: ["template", "group_id", "template_id"],
  buildSearchObject(context) {
    // Based on context received, build search query
    // structure with defaults where applicable.
    if (!context) {
      console.error("Query context object missing")
      return {}
    }

    const searchObject = {}

    // ===
    // Indices
    // ===
    //  input: ["users", "groups"]
    //  output: [({ i: "users" }, { i: "groups" })]

    if (context.indices) {
      searchObject.indices = context.indices.map((index) => {
        return {
          i: index,
        }
      })
    } else {
      searchObject.indices = []
    }

    // ===
    // Filters
    // ===
    //  input: [{ attr: "name", type: 'text', value: searchTerm }]
    //  output: [{ a: "name", t: "text", v: searchTerm }]
    if (context.filters) {
      searchObject.filters = context.filters.map((filter) => {
        return {
          a: filter.attr,
          a2: filter.attr2,
          t: filter.type,
          v: filter.value,
          v2: filter.value2,
          vrb: filter.verb,
          n: filter.nested,
          nv: filter.nestedVerb,
        }
      })
    } else {
      searchObject.filters = []
    }

    // ===
    // Sorts
    // ===
    //  input: [{ attr: "name", order: "asc" }]
    //  output: [{ a: "name.raw", o: "asc" }]

    if (context.sorts) {
      searchObject.sorts = context.sorts.map((sort) => {
        return {
          a: sort.attr,
          o: sort.order,
        }
      })
    } else {
      searchObject.sorts = []
    }

    // ===
    // Includes (attributes to return)
    // ===
    //  input: ["id"]
    //  output: [{ a: "id" }]

    if (context.includes) {
      searchObject.includes = context.includes.map((attr) => {
        return {
          a: attr,
        }
      })
    } else {
      searchObject.includes = []
    }

    // ===
    // Custom Attributes (custom attributes to load with model)
    // ===

    if (context.customAttrs) {
      searchObject.custom_attrs = context.customAttrs
    }

    // ===
    // Custom Filters
    // ===

    if (context.customFilters) {
      searchObject.custom_filters = context.customFilters
    }

    // ===
    // Keyword Searches
    // ===

    if (context.keywords) {
      searchObject.keywords = context.keywords
    }

    if (context.graph) {
      searchObject.graph = context.graph
    }

    // ===
    // Meta
    // ===

    searchObject.meta = {
      admin: context.admin || false,
      user_admin: context.user_admin || false,
      max_policy_set: context.max_policy_set || false,
      split_response: context.split_response || false,
      page: toInteger(context.page) || 1,
      per_page: toInteger(context.per_page) || 50,
      find_company: context.find_company || false,
    }

    return JSON.stringify(searchObject)
  },
  buildAccessPolicyWords(context = {}) {
    // ===
    // Policy Words
    // ===
    // These are used to ask for a specific subset of max viewable records.
    // ex: show me all X in Nucla network: "n31:0"
    // - scope: "network = n"
    // - id: "31"
    // - action: "read = 0"

    const policyWords = []

    // input: { networks: [{ id: 31, action: "read" }] }
    // output: "n31:0"

    // Iterate through the scopes of access requested, ie. networks.
    Object.keys(context).forEach((key) => {
      // For each scope, build the policy words.
      context[key].forEach((model) => {
        // eslint-disable-next-line
        policyWords.push(
          key[0] + model.id + ":" + this.policyActionMap(model.action)
        )
      })
    })
    return policyWords
  },
  policyActionMap(action) {
    return ["read", "write", "admin"].indexOf(action)
  },
  keywordsObject(searchTerm = null, filters = []) {
    const arr = []

    if (!searchTerm) {
      return arr
    }

    // Indices params check
    if (filters.length === 0) {
      console.error("Filters must be passed into keywordsObject")
      return
    } else {
      const filtersParamCheck = filters.map(
        (i) => i instanceof Object && "filter" in i
      )
      if (filtersParamCheck.includes(false)) {
        const filtersExample = [
          { filter: "group_companies", only: ["kt_name"] },
          { filter: "reports" },
        ]
        console.error(
          "A filter MUST be an object AND contain a key of `filter`"
        )
        console.error(
          "filters example: ",
          filtersExample,
          "filters passed in: ",
          filters
        )
        return
      }
    }

    const obj = {}
    obj.key = searchTerm
    obj.filters = []

    for (var i = 0; i < filters.length; i++) {
      const filter = _.get(filters[i], "filter")
      const groupId = _.get(filters[i], "groupId")
      const only = _.get(filters[i], "only", [])

      obj.filters.push({
        filter: filter,
        group_id: groupId,
        only: only,
      })
    }
    arr.push(obj)

    return arr
  },
}
